<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item text-center">
        <b-link v-if="pageType !== 'app-detail'" class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
        <feather-icon v-else icon="ArrowLeftIcon" size="21" class="cursor-pointer ml-50" @click="$router.go(-1)" />

      </li>
    </ul>
    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-lg-flex">
      <search-app v-if="pageType === 'home'" />
      <search-my-app v-else-if="pageType === 'myApps'" />
      <search-version v-else-if="pageType === 'app-detail'" />
    </div>

  </div>
</template>

<script>
import {
  BLink, BNavbarNav,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppBreadcrumb from '@/components/AppBreadcrumb.vue'
import SearchApp from '@/components/SearchApp.vue'
import SearchMyApp from '@/components/SearchMyApp.vue'
import SearchVersion from '@/components/SearchVersion.vue'
import router from '@/router'

export default {
  components: {
    BLink,
    ToastificationContent,
    AppBreadcrumb,
    SearchApp,
    SearchMyApp,
    SearchVersion,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => { },
    },
  },
  data() {
    return {
      pageType: router.currentRoute.name,
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        this.pageType = to.name || 'home'
      },
    },
  },

}
</script>

export default [

  {
    header: 'Store',
    action: 'read',
    resource: 'buildList||home',
  },
  {
    title: 'Apps',
    route: 'home',
    icon: 'ShoppingBagIcon',
    action: 'read',
    resource: 'buildList',
    id: 'home',
  },
  {
    title: 'My Apps',
    route: 'myApps',
    icon: 'UserIcon',
    action: 'read',
    resource: 'buildList',
    id: 'myApps',
  },
  {
    header: 'Logs',
    action: 'read',
    resource: 'storeDownloadLogs',
  },
  {
    title: 'Download Logs',
    route: 'download-logs',
    icon: 'ActivityIcon',
    action: 'read',
    resource: 'storeDownloadLogs',
    id: 'download-logs',
  },
]

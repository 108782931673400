<template>
  <li class="nav-item nav-search">
    <a href="javascript:void(0)" class="nav-link nav-link-search">
      <feather-icon icon="SearchIcon" size="21" />
    </a>
    <div class="search-input" :class="{'open': true}">
      <b-form-input v-model="searchApp" @input="searchFetchApps" placeholder="Search App" autocomplete="off" />
    </div>
  </li>
</template>

<script>
import {
  BFormInput,
} from 'bootstrap-vue'
import useAppApi from '@/composables/useAppApi'

export default {
  components: {
    BFormInput,
  },
  setup() {
    const {
      searchApp,
      searchFetchApps
    } = useAppApi()

    return {
      searchApp,
      searchFetchApps
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.navbar-container .search-input.open {
  left: auto !important;
}

.navbar-container .search-input.open input {
  padding-left: 14px;
}

.navbar-container .search-input.open {
  width: 84% !important;
  top: -4px;
}

.dark-layout {
  .navbar-container .search-input .search-list {
    background: #293046 !important;
    color: #f7f7f7;
  }

  .navbar-container span {
    color: #f7f7f7 !important;
  }

  .navbar-container .search-input.open {
    background: #293046 !important;
  }
}
</style>
